.containerIndustry{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 2%;
    row-gap: 8%;
    margin-left: 8%;
    margin-top: 5%;
    margin-bottom: 10%;
}



@media screen  and (max-width:800px){

    .containerIndustry{
        margin-left: 10%
    }
  
}



@media screen  and (max-width:400px){

    .containerIndustry{
        margin-left: 10%
    }

}
