.containerTrucks{
    display: grid;
    grid-template-rows: 50% 50%;

    
    row-gap: 8%;
    margin-left: 8%;
    margin-top: 5%;
    margin-bottom: 10%;
}

.smallTrucks{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 2%;
}

.bigTrucks{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 2%;
}








@media screen  and (max-width:800px){

    .containerTrucks{
        margin-left: 10%
    }

    
}



@media screen  and (max-width:400px){

    .containerTrucks{
        margin-left: 10%
    }


    

}