
.containerFooter{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 2%;
    row-gap: 8%;
    margin-left: 12%;
    margin-top: 5%;
    margin-bottom: 10%;
    justify-items: center;
}

.colFooter{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.colFooterImg{
    margin-left: 30%;
}


.imgFooter{
    width: 60%;
    height: 80%;
}

.imgFooterPhone{
    width: 50%;
    height: 70%;
}

.imgFooterLI{
    width: 80%;
    height: 80%;
}

p.textCardPFooter{
    text-align: center;
    font-weight: 700;
    font-size: 2.5vw;
}

p.textCardPFooterPhone{
    font-size: 2vw;
}

    #externo{
        font-size: small;
    }






@media screen  and (max-width:800px){

    .containerFooter{
        margin-left: 4%;
        margin-top: 10%;
    }

    .imgFooter{
        width: 60%;
        height: 80%;
    }

    .imgFooterPhone{
        width: 60%;
        height: 80%;
    }

    .imgFooterLI{
        width: 80%;
        height: 80%;
    }

    p.textCardPFooter{
        font-size: 2.5vw;
    }

    p.textCardPFooterPhone{
        font-size: 2vw;
    }
}





@media screen  and (max-width:400px){
    
    .containerFooter{
        margin-left: 4%;
        margin-top: 15%;
    }

    .imgFooter{
        width: 60%;
        height: 80%;
    }

    .imgFooterPhone{
        width: 60%;
        height: 80%;
    }

    .imgFooterLI{
        width: 80%;
        height: 80%;
    }

    p.textCardPFooter{
        font-size: 3vw;
    }

    p.textCardPFooterPhone{
        font-size: 2vw;
    }
}
