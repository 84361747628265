.container-fluid{
    margin-left: 0%;
}

.card text-center{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
}



@media screen  and (max-width:800px){

    .container-fluid{
        margin-left: 5%;
    }

    .col{
        width: 185px;
    }

    .card text-center{
        width: 150px;
    }
}



@media screen  and (max-width:400px){
    
    .container-fluid{
        margin-left: 8%;
    }
    
    .col{
        margin-top: 5px;
        width: 170px;
    }

    .card-body{
        height: 84px;

    }


}

