.container-fluidManufacturing{
    position: relative;
    display: inline-block;
    text-align: center;
    margin-left: 5%;
}

.imgManufacturing{
    border-radius: 5%;
    opacity: 0.6;
}

.texto-encimaManufacturing{
    position: absolute;
    top: 40px;
    left: 40px;
    right: 50%;
    font-family: sans-serif;
    font-size: 2vw;
    font-weight: 900;
    text-align: justify;
    color: black;
    -webkit-text-stroke: 0.3px blue;
}


@media screen  and (max-width:800px){

    .texto-encimaManufacturing{
        position: absolute;
        top: 20px;
        left: 20px;
        right: 50%;
        font-family: sans-serif;
        font-size: 2vw;
        font-weight: 790;
        text-align: justify;
        -webkit-text-stroke: 0.3px blue;
    }
}

@media screen  and (max-width:400px){
    
    .texto-encimaManufacturing{
        position: absolute;
        top: 5px;
        left: 10px;
        right: 50%;
        font-family: sans-serif;
        font-size: 2vw;
        font-weight: 790;
        text-align: justify;
        -webkit-text-stroke: 0.3px blue;
    }
}