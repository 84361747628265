body { min-height: 100vh; }

.Homediv:fullscreen { height: 100vh; }

.HomeTop{
    margin-top: 5%;
    margin-left: 7%;
    font-size: 8vh;
    font-weight: bold;
}

.HomeTopP{
    margin-top: 4%;
    margin-left: 7%;
    font-size: 8vh;
    font-weight: bold;
}

.HomeTopA{
    margin-top: 4%;
    margin-left: 7%;
    font-size: 7vh;
    font-weight: bold;
    color: green;
    -webkit-text-stroke: 1.8px black;
}

.HomeBottom{
    margin-top: 5%;
    margin-left: 0%;
    font-size: 7vh;
    font-weight: bold;
    text-align: center;
}



@media screen  and (max-width:800px){

    .HomeTop{
        margin-top: 7%;
        margin-left: 12%;
        font-size: 4vh;
        font-weight: bold;
    }

    .HomeTopP{
        margin-top: 4%;
        margin-left: 12%;
        font-size: 3.8vh;
        font-weight: bold;
    }

    .HomeTopA{
        margin-top: 4%;
        margin-left: 12%;
        font-size: 5.5vh;
        font-weight: bold;
    }

    .HomeBottom{
        margin-top: 5%;
        margin-left: 0%;
        font-size: 4.5vh;
        font-weight: bold;
        text-align: center;
    }

}





@media screen  and (max-width:400px){

    .HomeTop{
        margin-top: 10%;
        margin-left: 9%;
        font-size: 3vh;
        font-weight: bold;
    }

    .HomeTopP{
        margin-top: 5%;
        margin-left: 9%;
        font-size: 3vh;
        font-weight: bold;
    }

    .HomeTopA{
        margin-top: 4%;
        margin-left: 10%;
        font-size: 4.5vh;
        font-weight: bold;
    }

    .HomeBottom{
        margin-top: 5%;
        margin-left: 0%;
        font-size: 3.5vh;
        font-weight: bold;
        text-align: center;
    }

}
