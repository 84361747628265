
nav{
    background-color: rgb(237, 242, 243);
}

.containerNavbar{
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 3%;
    align-items: center;
    justify-items: start;
    margin-left: 8%;
    margin-top: 2%;
    margin-bottom: 3%;
}

.containerLogoButtons{
    display: grid;
    grid-template-columns: 25% 20% 55%;
    column-gap: 5%;
    align-items: center;
    justify-items: center;
    margin-right: 3%;
}

.containerFormNavbar{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 5%;
    align-items: center;
    justify-items: center;
}

.auxiliarLogin{
    width: 10vh;
}

.imgNavbar{
    opacity: 1;
    height: 90%;
    width: 90%;    
}




@media screen  and (max-width:800px){

    .containerNavbar{
        margin-left: 5%;
        column-gap: 0%;
    }

}




@media screen  and (max-width:400px){

    nav{
        margin-top: 5%;
    }


    .containerLogoButtons{
        margin-top: 8%;
        margin-bottom: 3%;
    }

    .auxiliarLogin{
        width: 3vh;
    }

}