
.containerCustomers{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 2%;
    row-gap: 8%;
    margin-left: 15%;
    margin-top: 5%;
    margin-bottom: 10%;
}


.cardCustomer{
    width: 60%;
    height: 70%;
}



@media screen  and (max-width:800px){

    .containerCustomers{
        margin-left: 12%;
    }


    .cardCustomer{
        width: 60%;
        height: 70%;
    }

}


@media screen  and (max-width:400px){

    .containerCustomers{
        margin-left: 5%;
        column-gap: 5%;
        row-gap: 5%;
    }


    .cardCustomer{
        width: 60%;
        height: 70%;
    }

}


